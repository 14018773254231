import React from "react"
import PageRoot from "../components/PageRoot"
import { useTranslation } from "react-i18next"
import "../style/landing.less"
import {
  Col, Row
} from "antd"
import PlanSelector from "../components/customFormItems/PlanSelector"
import { navigate } from "gatsby"
import PlanComparisonTable from "../components/PlanComparisonTable"



const rootLayoutRowSettings = {
  justify: "space-around",
}

const LandingPage = () => {
  const { t } = useTranslation()

  return (
    <PageRoot title={t("label:pricingPage")} description={t("message:pricingPageDescription")} image="/assets/images/pricing-image-3.jpg">
      <div className="pricing-page">
        <section className="pb pt bg-white plan-selector">
          <div style={{ maxWidth: 1280, margin: "auto" }}>
            <Row justify="space-around" {...rootLayoutRowSettings}>
              <Col xs={24}>
                <PlanSelector
                  onPlanSelect={planId => {
                    navigate(`/auth/sign-up?planId=${planId}`)
                  }}
                />
                <PlanComparisonTable />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </PageRoot>
  )
}

export default LandingPage
